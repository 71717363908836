import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import BVideo from '../Assets/Primaris_Intro.mp4';

function Background() {
    const videoRef = useRef(null);
    // eslint-disable-next-line
    const [videoLoaded, setVideoLoaded] = useState(false);
    // eslint-disable-next-line
    const [videoError, setVideoError] = useState(false);
    const [userInteracted, setUserInteracted] = useState(false); // Estado para rastrear si el usuario ha interactuado con la página

    useEffect(() => {
        const video = videoRef.current;

        const handleEnded = () => {
            if (video) {
                video.currentTime = video.duration - 3.5;
                video.play();
            }
        };

        const handleLoadedMetadata = () => {
            setVideoLoaded(true);
        };

        const handleError = () => {
            setVideoError(true);
        };

        if (video) {
            video.addEventListener('ended', handleEnded);
            video.addEventListener('loadedmetadata', handleLoadedMetadata);
            video.addEventListener('error', handleError);

            video.muted = true;

            if (userInteracted) {
                video.muted = false;
            }

            return () => {
                video.removeEventListener('ended', handleEnded);
                video.removeEventListener('loadedmetadata', handleLoadedMetadata);
                video.removeEventListener('error', handleError);
            };
        }
    }, [userInteracted]);

    const handleUserInteraction = () => {
        setUserInteracted(true);
    };

    return (
        <div onClick={handleUserInteraction}> 
            <BackgroundVideo 
                ref={videoRef} 
                autoPlay 
                loop={false}
            >
                <source src={BVideo} type="video/mp4" />
            </BackgroundVideo>
        </div>
    );
}

const BackgroundVideo = styled.video`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export default Background;
