import React, { useState } from 'react';
import styled from 'styled-components';
import Discord from '../Assets/SocialMedia/disc.png';
import DiscordHover from '../Assets/SocialMedia/disc_.png';
import Tg from '../Assets/SocialMedia/tg.png';
import TgHover from '../Assets/SocialMedia/tg_.png';
import X from '../Assets/SocialMedia/x.png';
import XHover from '../Assets/SocialMedia/x_.png';
import Tik from '../Assets/SocialMedia/tik.png';
import TikHover from '../Assets/SocialMedia/tik_.png';

const Footer = () => {

    const [hovered, setHovered] = useState({
        discord: false,
        tg: false,
        x: false,
        ins: false,
        tik: false
    });

    const handleMouseEnter = (icon) => {
        setHovered({ ...hovered, [icon]: true });
    };

    const handleMouseLeave = (icon) => {
        setHovered({ ...hovered, [icon]: false });
    };

    return (
       <>
        <FooterContainer>
            <ul>
                <StyledLi> 
                    <a href="https://discord.gg/3y4y268Hwk" target="_blank" rel="noopener noreferrer">
                        <Icons
                            src={hovered.discord ? DiscordHover : Discord}
                            alt="Discord"
                            onMouseEnter={() => handleMouseEnter('discord')}
                            onMouseLeave={() => handleMouseLeave('discord')}
                        />
                    </a>
                </StyledLi>
                <StyledLi>
                    <a href="https://t.me/+rLN7KAvG6703OWM0" target="_blank" rel="noopener noreferrer">
                        <Icons
                            src={hovered.tg ? TgHover : Tg}
                            alt="Telegram"
                            onMouseEnter={() => handleMouseEnter('tg')}
                            onMouseLeave={() => handleMouseLeave('tg')}
                        />
                    </a>
                </StyledLi>
                <StyledLi>
                    <a href="https://x.com/metaversalwar" target="_blank" rel="noopener noreferrer">
                        <Icons
                            src={hovered.x ? XHover : X}
                            alt="X"
                            onMouseEnter={() => handleMouseEnter('x')}
                            onMouseLeave={() => handleMouseLeave('x')}
                        />
                    </a>
                </StyledLi>
                <StyledLi> 
                    <a href="https://www.tiktok.com/@metaversalwar" target="_blank" rel="noopener noreferrer">
                        <Icons
                            src={hovered.tik ? TikHover : Tik}
                            alt="Tik Tok"
                            onMouseEnter={() => handleMouseEnter('tik')}
                            onMouseLeave={() => handleMouseLeave('tik')}
                        />
                    </a>
                </StyledLi>
            </ul>
        </FooterContainer>
        </> 
    );
}
const FooterContainer = styled.footer`
    position: absolute;
    bottom: 0;
    width: 35%;
    left: 31.5%;
    right: 33.5%;
    z-index: 10;
    display: flex;
    border-radius: 10px 10px 0 0;
    background-color: #131213;
    padding: 1rem;
    align-items: center;
    justify-content: center;

    ul {
        list-style-type: none;
        display: flex;
        gap: 4rem;
        color: #FFFFFF;
        transition: 0.7s ease-in-out;
        &:hover {
            color:#2d69fd;
        }

        @media (max-width: 576px) {
            gap: 2rem;
        }
    }

    @media (max-width: 1000px) {
        width: 50%;
        left: 25%;
        right: 25%;
    }

    @media (max-width: 630px) {
        width: 100%;
        left: 0%;
        right: 0%;
        padding: 0.8rem 0 0.5rem 0;

        ul {
            justify-content: space-around;
        }
    }
`;

const Icons = styled.img`
    width: 2.5vh;
    height: auto;
    opacity: 90%;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }
    @media (max-width: 630px){
        width: 1.8vh;
    }

`;

const StyledLi = styled.li`
    position: relative;

    &:not(:last-child)::after {
        content: '';
        position: absolute;
        height: 70%;
        width: 1px;
        background: linear-gradient(transparent 0%, #1b1b1b 30%, #1b1b1b 70%, transparent 100%),
        linear-gradient(transparent 0%, transparent 40%, #1b1b1b 60%, transparent 100%);
        top: 10%;
        right: -2rem;
    }

    @media (max-width: 630px) {
        &:not(:last-child)::after {
            right: -1rem;
        }
    }
`;

export default Footer;
