import React from "react";
import LandingPage from "./Components/Landing";

function App() {
  return (
    <LandingPage /> 
  );
}

export default App;
