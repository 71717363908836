import React from 'react';
import styled from 'styled-components';
import Background from './Background';
import Navbar from './Navbar';
import Footer from './Footer';
import PlayButton from './PlayButton';
import Logo from '../Assets/PRIMARISMETAVERSALWARLOGO0.png'
// eslint-disable-next-line
import { BrowserRouter as Router } from "react-router-dom";

function LandingPage() {
    return (
        <Router>
            <MainContainer>
                <Background/>
                <CenterContent>
                    <StyledLogo src={Logo} alt="Logo" />
                </CenterContent>
                <Navbar/>
                    <PlayButton href="https://app.metaversalwar.com/" target="_blank" rel="noopener noreferrer">
                        Play Testnet Game
                    </PlayButton>
                <Footer/>
            </MainContainer>
        </Router>

    );
}                

const MainContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
`;

const CenterContent = styled.div`
    position: absolute;
    width: 70%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 10;

    @media (max-width: 768px) {
        width: 80%;
    }

    @media (max-width: 576px) {
        width: 90%;
    }

    @media (max-width: 360px) {
        width: 95%;
    }
`;

const StyledLogo = styled.img`
    width: 100%;
    height: auto;
`;

export default LandingPage;
