import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


const PlayButton = ({ to, href, target, rel, children }) => {

    if (href) {
        return (
            <StyledAnchor href={href} target={target} rel={rel}>
                {children}
            </StyledAnchor>
        );
    }

    return (
        <StyledButton to={to}>
            {children}
        </StyledButton>
    );
}

export default PlayButton;


const commonStyles = `
    position: absolute;
    top: 69%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: inline-block;
    padding: 15px 28px;
    background-color: #1b1b1b;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1rem;
    border: 2px solid #8D51CF;
    border-radius: 20px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.5s ease;
    &:hover {
        background-color: #8D51CF;
        color: rgba(255, 255, 255, 1);
    }

    @media (max-width: 340px) {
        width: 60%;
        left: 50%;
        right: auto;
        font-size: 0.6rem;
        top: 70%;
    }
`;

const StyledButton = styled(Link)`
    ${commonStyles}
`;

const StyledAnchor = styled.a`
    ${commonStyles}
`;